<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(91.24 0 0 91.24 -33416.2305 11940.6396)" gradientUnits="userSpaceOnUse" id="icon-image_00000152952888788410948720000008989309997114340226_" x1="367.721" x2="366.841" y1="-130.2654" y2="-129.5254">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M137.1,127V56  c0-5.6-4.5-10.1-10.1-10.1H56c-5.6,0-10.1,4.5-10.1,10.1v71c0,5.6,4.5,10.1,10.1,10.1h71C132.6,137.1,137.1,132.6,137.1,127z   M73.8,99.1l12.7,15.3l17.7-22.9l22.8,30.4H56L73.8,99.1z" id="icon-image" style="fill:url(#icon-image_00000152952888788410948720000008989309997114340226_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>